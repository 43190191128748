import { PROFILE_EMPLOYEE_ROLES, PROFILE_OPERATION_TYPE } from 'consts/profile';
import { SocialEnum } from 'consts/social';
import { contactType } from 'data';
import moment from 'moment';
import {
    filter,
    find,
    includes,
    indexBy,
    not,
    path,
    pathOr,
    prop,
    propEq,
    trim,
} from 'ramda';
import { createSelector } from 'reselect';
import {
    RULE_APPROVALS,
    RULE_SOCIAL_APPLE,
    RULE_SOCIAL_AUTH,
    RULE_SOCIAL_FACEBOOK,
    RULE_SOCIAL_GOOGLE,
    RULE_SOCIAL_INSTAGRAM,
    RULE_SOCIAL_TINKOFF,
    RULE_SOCIAL_VKONTAKTE,
} from 'utils/allowFeature';
import { isFading } from 'utils/fadingTime';
import { splitPhone } from 'utils/format';

import { getRawCitiesWithName } from '../countries';
import {
    prepareDocumentData,
    prepareDocumentDates,
} from '../documents/helpers';
import { getLocale } from '../languages';

const BLANK = 'BLANK';
const INSTAGRAM_MEDIA = 'INSTAGRAM_MEDIA';

export const getProfile = state => state.profile;
export const getProfileData = state => getProfile(state).data;
export const getProfileDocuments = state => getProfileData(state).documents;
const getProfileRaw = state => getProfile(state).raw || {};
const getProfileAddressEstate = pathOr(null, [
    'profile',
    'data',
    'address',
    'Estate',
]);
const getProfileAddressHouse = pathOr(null, [
    'profile',
    'data',
    'address',
    'house',
]);

export const getProfileUnavailableOperations = state =>
    state.profile.unavailableOperations;

export const getIsBlockedAllActionsMilles = state => {
    const milesActions = Object.values(PROFILE_OPERATION_TYPE).filter(item =>
        /^MILES_.*$/.test(item),
    );

    return milesActions.every(action =>
        state.profile.unavailableOperations.includes(action),
    );
};

// Загружен ли профиль
export const isInitialized = state => state.profile.isInitialized;
export const isLoaded = state => isFading(state.profile.fadingTime);
export const isLoading = state => state.profile.isLoading;
export const isError = state => state.profile.isError;

// Флаг редактирования
export const getIsEdit = (id, state) =>
    includes(id, state.profile.isEdit || []);

// Получение имени пользователя
export const getIgnoreCase = (
    state,
    path = [],
    ignoreCaseProps = [],
    defaultValue = null,
) => {
    const lower = pathOr(
        defaultValue,
        path.map(item =>
            ignoreCaseProps.includes(item) ? item.toLowerCase() : item,
        ),
        state,
    );

    const upper = pathOr(
        defaultValue,
        path.map(item =>
            ignoreCaseProps.includes(item) ? item.toUpperCase() : item,
        ),
        state,
    );

    return lower || upper;
};

export const getNames = pathOr(null, ['profile', 'data', 'names']);

export const getNameByLocale = createSelector(
    [getNames, getLocale],
    (names, locale) => {
        const needLocale = locale === 'ru' ? 'ru' : 'en';

        return getIgnoreCase(names, [needLocale, 'firstName'], ['ru', 'en']);
    },
);

export const getName = (state, locale = null) => {
    const currentLocale = getLocale(state);
    const needLocale = locale || (currentLocale === 'ru' ? 'ru' : 'en');

    return getIgnoreCase(
        state,
        ['profile', 'data', 'names', needLocale, 'firstName'],
        ['ru', 'en'],
    );
};

export const getSurnameByLocale = createSelector(
    [getNames, getLocale],
    (names, locale) => {
        const needLocale = locale === 'ru' ? 'ru' : 'en';

        return getIgnoreCase(names, [needLocale, 'lastName'], ['ru', 'en']);
    },
);

// Получение фамилии пользователя
export const getSurname = (state, locale = null) => {
    const currentLocale = getLocale(state);
    const needLocale = locale || (currentLocale === 'ru' ? 'ru' : 'en');

    return getIgnoreCase(
        state,
        ['profile', 'data', 'names', needLocale, 'lastName'],
        ['ru', 'en'],
    );
};

export const getSocialProfileName = pathOr('', [
    'profile',
    'data',
    'socialAccounts',
    0,
    'name',
]);
export const getSocialAccounts = pathOr('', [
    'profile',
    'data',
    'socialAccounts',
]);

// Тип регистрации
export const getCustomerValue = pathOr(null, ['profile', 'data', 'type']);
export const getIsFullProfile = createSelector(
    getCustomerValue,
    profileType => profileType === 'FULL',
);

// Полное имя (рус. / англ.)
export const getFullName = createSelector(
    [
        getLocale,
        getName,
        getSurname,
        state => getName(state, 'en'),
        state => getSurname(state, 'en'),
    ],
    (locate, firstName, lastName, firstNameEn, lastNameEn) => {
        if (locate === 'ru') {
            return trim(
                [
                    [firstName, lastName].join(' '),
                    [firstNameEn, lastNameEn].join(' '),
                ].join(' / '),
            );
        }

        return trim([firstName, lastName].join(' '));
    },
);

export const getInstagramName = createSelector(
    getSocialAccounts,

    accounts => {
        if (!accounts) {
            return '';
        }

        const instagramAccount = accounts.find(
            ({ type }) => type === SocialEnum.Instagram,
        );

        return instagramAccount ? instagramAccount.name : '';
    },
);

// Полное имя рус.
export const getFullRusName = createSelector(
    [state => getName(state, 'ru'), state => getSurname(state, 'ru')],
    (firstName, lastName) => {
        return trim([firstName, lastName].join(' '));
    },
);

// Полное имя англ.
export const getFullEngName = createSelector(
    [state => getName(state, 'en'), state => getSurname(state, 'en')],
    (firstName, lastName) => {
        return trim([firstName, lastName].join(' '));
    },
);

// Дата рождения
export const getBirthdate = state => getProfileData(state).birthday;

// Пол
export const getGender = state => getProfileData(state).gender;

// Данные ФИО пользователя
export const getPersonalNames = createSelector(
    state => getProfileData(state).names,
    profile => ({
        firstname_en: getIgnoreCase(profile, ['en', 'firstName'], ['en'], ''),
        firstname_ru: getIgnoreCase(profile, ['ru', 'firstName'], ['ru'], ''),
        lastname_en: getIgnoreCase(profile, ['en', 'lastName'], ['en'], ''),
        lastname_ru: getIgnoreCase(profile, ['ru', 'lastName'], ['ru'], ''),
    }),
);

export const getContacts = createSelector(
    state => state.profile.contacts,
    contacts => {
        const { email, phone } = contacts;

        return [
            { ...email, isVerified: email.verified },
            { ...phone, isVerified: phone.verified },
        ];
    },
);

export const getEmailData = createSelector(getContacts, contacts =>
    contacts.find(contact => contact.type === contactType.EMAIL),
);

export const getIsAllContactsConfirmed = createSelector(getContacts, contacts =>
    contacts.every(contact => contact.isVerified),
);

export const getContactEditModalIsOpen = pathOr(false, [
    'profile',
    'contacts',
    'isModalOpen',
]);
export const getCurrentEditContactType = pathOr('', [
    'profile',
    'contacts',
    'currentEditType',
]);
export const getIsOpenPhoneInfoAboutFurtherChanges = pathOr(false, [
    'profile',
    'contacts',
    'phone',
    'isOpenInfoAboutFurtherChanges',
]);
export const getCurrentEditContact = createSelector(
    getContacts,
    getCurrentEditContactType,
    (contacts, type) => contacts.find(contact => contact.type === type),
);

export const getRemainingSecondsForContact = (state, type) => {
    const codeRequestedTime = pathOr(
        null,
        ['profile', 'contacts', type, 'sentTime'],
        state,
    );

    if (not(codeRequestedTime)) {
        return 0;
    }

    const seconds = moment
        .duration(moment(codeRequestedTime).add(120, 's').diff(moment()))
        .asSeconds();

    return seconds > 0 ? seconds : 0;
};

// E-mail
export const getEmail = pathOr(null, [
    'profile',
    'data',
    'contacts',
    'EMAIL',
    'value',
]);
export const getIsEmailConfirmed = state =>
    pathOr(false, ['profile', 'contacts', 'email', 'verified'], state);

export const getEmailConfirmed = createSelector(
    [getIsEmailConfirmed, getEmail],
    (confirmed, email) => confirmed && !!email,
);

// Телефон в формате
export const getPhoneValue = state =>
    pathOr('', ['contacts', 'PHONE', 'value'], getProfileData(state));
export const getIsTelephoneConfirmed = state =>
    pathOr(false, ['profile', 'contacts', 'phone', 'verified'], state);

export const getTelephone = createSelector(getPhoneValue, phone => {
    if (phone) {
        const { areaCode, countryCode, number } = splitPhone(phone);

        if (!areaCode || !countryCode || !number) {
            return null;
        }

        return `+${countryCode} ${areaCode} ${number}`;
    }

    return '';
});

export const getTelephoneConfirmed = createSelector(
    [getIsTelephoneConfirmed, getTelephone],
    (confirmed, telephone) => confirmed && !!telephone,
);

export const getIsWeakPassword = state =>
    RULE_SOCIAL_AUTH &&
    pathOr(false, ['profile', 'meta', 'isWeakPassword'], state) &&
    !pathOr(false, ['profile', 'meta', 'fromSocial'], state);

// Employee

const employeePath = ['profile', 'data', 'employee'];
const companyPath = ['profile', 'data', 'company'];

export const getEmployee = pathOr(null, [...employeePath]);
export const getEmployeeId = pathOr('', [...employeePath, 'id']);
export const getEmployeePermissions = pathOr(
    [],
    ['profile', 'data', 'employee', 'permissions'],
);

export const getCompanySyncEnabled = pathOr(false, [
    ...employeePath,
    'profileSync',
    'enabled',
]);

export const getCompanyId = pathOr(null, [...companyPath, 'id']);
export const getCompanyCorporateId = pathOr(null, [
    ...companyPath,
    'corporateId',
]);
export const getCompanyName = pathOr(null, [...companyPath, 'name']);

export const getCompanyPosition = pathOr(null, [...employeePath, 'role']);

export const getStatus = state => path(['profile', 'status'], state);
export const getProvider = state => path(['profile', 'provider'], state);

export const hasCompany = createSelector(
    getCompanyName,
    getCompanyPosition,
    (hasName, hasPosition) => !!hasName && !!hasPosition,
);
export const getEmployeeRole = createSelector(
    getEmployeePermissions,
    getCompanyPosition,
    (permissions, role) => {
        if (
            role === PROFILE_EMPLOYEE_ROLES.EMPLOYEE &&
            permissions.includes('EMPLOYEES_INFO')
        ) {
            return PROFILE_EMPLOYEE_ROLES.TRAVEL_MANAGER;
        }

        return role;
    },
);
export const getIsAnEmployee = createSelector(
    getEmployee,
    employee => !!employee,
);

// OLD SELECTORS
/* eslint-disable complexity */
export const getFullAddress = createSelector(
    state => getProfileData(state).address,
    state => state.countries.items,
    (profile, countries) => {
        const address = [];

        if (profile.zip && profile.zip !== BLANK) {
            address.push(profile.zip);
        }

        if (profile.country && profile.country !== BLANK) {
            const countriesByCode = indexBy(prop('iso'), countries);
            const country = countriesByCode[profile.country] || { names: {} };

            address.push(
                country.names.ru || country.names.en || profile.country,
            );
        }

        if (profile.city && profile.city !== BLANK) {
            address.push(profile.city);
        }

        if (profile.addressLine && profile.addressLine !== BLANK) {
            address.push(profile.addressLine);
        }

        const house = [];

        if (profile.Estate && profile.Estate !== BLANK) {
            house.push(profile.Estate);
        } else if (profile.house && profile.house !== BLANK) {
            house.push(profile.house);
        }

        if (profile.bulk && profile.bulk !== BLANK) {
            house.push(profile.bulk);
        }

        if (house.length) {
            address.push(house.join(' / '));
        }

        if (profile.building && profile.building !== BLANK) {
            address.push(`строение ${profile.building}`);
        }

        if (profile.flat && profile.flat !== BLANK) {
            address.push(`кв. ${profile.flat}`);
        }

        return address.length ? address.join(', ') : '';
    },
);
/* eslint-enable complexity */

export const getAddressCountry = state => {
    const country = pathOr(
        null,
        ['profile', 'data', 'address', 'country'],
        state,
    );

    return country !== BLANK ? country : '';
};

export const getAddressCity = state => {
    const city = pathOr(null, ['profile', 'data', 'address', 'city'], state);

    return city !== BLANK ? city : '';
};

export const getAddressLine = state => {
    const addressLine = pathOr(
        null,
        ['profile', 'data', 'address', 'addressLine'],
        state,
    );

    return addressLine !== BLANK ? addressLine : '';
};

export const getAddressHouse = createSelector(
    [getProfileAddressEstate, getProfileAddressHouse],
    (Estate, house) => {
        if (Estate && Estate !== BLANK) {
            return Estate;
        }

        if (house && house !== BLANK) {
            return house;
        }

        return null;
    },
);

export const getAddressBulk = state => {
    const bulk = pathOr(null, ['profile', 'data', 'address', 'bulk'], state);

    return bulk !== BLANK ? bulk : '';
};

export const getAddressBuilding = state => {
    const building = pathOr(
        null,
        ['profile', 'data', 'address', 'building'],
        state,
    );

    return building !== BLANK ? building : '';
};

export const getAddressFlat = state => {
    const flat = pathOr(null, ['profile', 'data', 'address', 'flat'], state);

    return flat !== BLANK ? flat : '';
};

export const getAddressZip = state => {
    const zip = pathOr(null, ['profile', 'data', 'address', 'zip'], state);

    return zip !== BLANK ? zip : '';
};

export const getProfileId = pathOr(null, ['profile', 'data', 'id']);

const getPreferences = pathOr({}, ['profile', 'data', 'preferences']);

export const getHomeAirport = createSelector(
    getPreferences,
    pathOr(null, ['homeAirport']),
);
export const getPreparedPreferences = createSelector(
    [getPreferences, getRawCitiesWithName],
    (
        {
            distributionChannels,
            doctor,
            doctorType,
            doctorTypesDictionary,
            error,
            homeAirport,
            ...preferences
        },
        cities,
    ) => {
        const homeCity = homeAirport
            ? cities.find(
                  ({ childrens, iata, ibeCode }) =>
                      [iata, ibeCode].includes(homeAirport) ||
                      childrens.some(child => child.ibeCode === homeAirport),
              )
            : null;

        const preparedPreferences = {
            ...preferences,
            accountSubscription: false,
            doctor,
            emailChannel: !!(
                distributionChannels && distributionChannels.includes('EMAIL')
            ),
            homeAirport: homeCity,
            mobileChannel: !!(
                distributionChannels && distributionChannels.includes('SMS')
            ),
            newsSubscription: false,
        };

        if (homeCity) {
            preparedPreferences.homeAirport = {
                label: homeCity.names,
                value: homeCity.iata,
            };
        }

        if (doctor) {
            preparedPreferences.doctorType = doctorType
                ? { label: doctorType.description, value: doctorType.code }
                : null;
        }

        return preparedPreferences;
    },
);

export const getRawProfile = createSelector(
    getProfileRaw,
    getPhoneValue,
    getEmail,
    getName,
    getSurname,
    (raw, phone, email, firstName, lastName) => ({
        ...raw,
        email,
        firstName,
        lastName,
        phone: {
            phoneString: phone,
        },
    }),
);

export const getProfileTags = pathOr('', [
    'profile',
    'data',
    'preferences',
    'tags',
]);
export const getAvatarPreviewLink = pathOr('', [
    'profile',
    'data',
    'avatar',
    'previewLink',
]);
export const getIsAvatarSaving = pathOr(false, [
    'profile',
    'data',
    'avatar',
    'isSaving',
]);
export const getIsAvatarDeleting = pathOr(false, [
    'profile',
    'data',
    'avatar',
    'isDeleting',
]);
export const getAvatarError = pathOr('', [
    'profile',
    'data',
    'avatar',
    'error',
]);

/* Social Accounts */

export const getSocialPopup = state =>
    pathOr({}, ['profile', 'social', 'popup'], state);

const allowedSocials = [
    [RULE_SOCIAL_FACEBOOK, SocialEnum.Facebook],
    [RULE_SOCIAL_VKONTAKTE, SocialEnum.Vk],
    [RULE_SOCIAL_GOOGLE, SocialEnum.Google],
    [RULE_SOCIAL_INSTAGRAM, SocialEnum.Instagram],
    [RULE_SOCIAL_APPLE, SocialEnum.Apple],
    [RULE_SOCIAL_TINKOFF, SocialEnum.Tinkoff],
]
    .filter(([flag]) => flag)
    .map(([, socialName]) => socialName);

export const getSocialLinked = state => {
    const items = pathOr([], ['profile', 'social', 'items'], state);

    return filter(({ type }) => allowedSocials.indexOf(type) !== -1)(items);
};

export const getSocialUnLinked = state => {
    const linked = getSocialLinked(state);

    return filter(item => !find(propEq('type', item))(linked), allowedSocials);
};

export const getIsSocialProfile = state =>
    pathOr(false, ['profile', 'meta', 'isWeakPassword'], state) &&
    pathOr(false, ['profile', 'meta', 'fromSocial'], state);

export const getIsShortProfile = state =>
    pathOr('NULL', ['profile', 'data', 'type'], state) === 'SHORT';

export const getIsShortSocialProfile = createSelector(
    getIsSocialProfile,
    getIsShortProfile,
    (isSocial, isShort) => isSocial && isShort,
);

export const getFromSocial = pathOr(false, ['profile', 'meta', 'fromSocial']);

// Получение Имени и Фамилии
export const getProfileNames = createSelector(
    getName,
    state => getName(state, 'en'),
    getSurname,
    state => getSurname(state, 'en'),
    getIsSocialProfile,
    getSocialProfileName,
    (
        nameLocal,
        nameEn,
        surnameLocal,
        surnameEn,
        isSocialProfile,
        nameSocial,
    ) => {
        const name = nameLocal || nameEn;
        const surname = surnameLocal || surnameEn;

        if (!name && !surname && isSocialProfile) {
            return {
                name: nameSocial,
                surname: '',
            };
        }

        return { isEnName: name === nameEn, name, surname };
    },
);

const getApprovals = state =>
    RULE_APPROVALS ? getProfile(state).approvals : [];

export const getCurrentApprovals = createSelector(getApprovals, approvals =>
    approvals.filter(approval => approval.status === 'APPROVED'),
);

export const getApprovalsAvailable = createSelector(
    getCurrentApprovals,
    approvals => !!approvals.length,
);
export const getPublicIdAvaliable = pathOr('', [
    'profile',
    'publicIdAvaliable',
]);
export const getPublicIdChecking = pathOr('', ['profile', 'publickIdChecking']);
export const getPublicIdCheckError = pathOr('', [
    'profile',
    'publicIdCheckError',
]);

export const getPublicSettings = pathOr({}, ['profile', 'publicSettings']);

export const getPublicId = createSelector(
    [getPublicSettings],
    ({ publicId }) => publicId,
);
export const getPublicMode = createSelector(
    [getPublicSettings],
    ({ mode }) => mode,
);
export const getPublicUrl = createSelector(
    [getPublicSettings],
    ({ publicUrl }) => publicUrl || '',
);
export const getPublicProfileName = createSelector(
    [getPublicSettings],
    ({ publicName }) => publicName || '',
);

export const getPublicPermissions = createSelector(
    [getPublicSettings],
    ({ permissions = [] }) => {
        if (!RULE_SOCIAL_INSTAGRAM) {
            return permissions.filter(({ type }) => type !== INSTAGRAM_MEDIA);
        }

        return permissions;
    },
);

const getPreparedDocuments = createSelector(
    [getProfileDocuments, getBirthdate, getIsShortProfile],
    (documents, birthday, isShortProfile) =>
        (documents || []).map(doc =>
            prepareDocumentData(prepareDocumentDates(doc), {
                birthday,
                isShortProfile,
            }),
        ),
);

const getSortedDocuments = createSelector(getPreparedDocuments, documents =>
    documents.sort((a, b) => {
        if (a.sort > b.sort) {
            return 1;
        }

        if (a.sort < b.sort) {
            return -1;
        }

        return 0;
    }),
);

export const getPrimaryDocument = createSelector(
    getSortedDocuments,
    documents => {
        if (!documents.length) {
            return null;
        }

        const sortedDates = documents
            .map(doc => doc.dateOfIssue)
            .filter(doc => !doc.isExpired)
            .sort((a, b) => (moment(a).isAfter(b) ? -1 : 1));

        return documents.find(
            ({ dateOfIssue }) => dateOfIssue === sortedDates[0],
        );
    },
);
